.container {
  padding: 0 1rem;
  margin: 3rem auto 6rem;
}

.header {
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media (min-width: 1024px) {
  .header {
    align-items: flex-start;
  }

  .logo {
    margin-left: 30px;
  }
}

.backToHome {
  margin: 3rem 0 0;
}

.logoLink {
  cursor: pointer;
}
