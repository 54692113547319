.container {
    display: flex;
    flex-direction: column;
}

.hero {
    margin-left: auto;
    margin-right: auto;
}

.hero h1 {
    color: var(--primary-color);
    text-align: center;
}

.hero img {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}

@media (min-width: 1024px) {
    .hero {
        justify-content: space-around;
    }

    .bannerImage {
        align-self: flex-start;
    }
}

.hero p {
    padding: 15px;
}

.tracksList ul {
    list-style-type: none;
    padding-left: 0;
}

.tracksList ul li {
    display: grid;
    grid-template-columns: 33% 66%;
    grid-template-rows: auto;
    grid-template-areas:
    "TrackLinks ."
    "Headline Headline";
    padding: 15px 15px;
    border: 1px solid var(--divider-color);
    margin: 20px 10px;
    background: white;
    overflow: hidden;
    height: 150px;
}

.tracksList ul li span {
    grid-area: TrackLinks;
    display: block;
    font-size: 0.8em;
}

.tracksList ul li:hover {
    border: 1px solid var(--accent-color);
    filter: drop-shadow(-15px 5px 0px var(--primary-color));
    animation: .1s linear moveup;
}

.tracksList ul li:hover h2 {
    text-decoration: underline;
    text-decoration-color: var(--secondary-color);
}

@keyframes moveup {
    100% {
        filter: drop-shadow(-20px 5px 1px var(--primary-color));
    }
}

.tracksList ul li a {
    color: var(--secondary-color);
    display: inline-block;
}

.tracksList ul li h2 {
    grid-area: Headline;
    font-size: 1.2em;
    white-space: pre-line;
    word-break: break-all;
    cursor: pointer;
    color: inherit;
    padding-top: 0px;
    padding-bottom: 10px;
    text-align: center;
    width: 100%;
}

.tracksList ul li small {
    margin: 0;
}

.tracksList ul li strong>small {
    color: var(--text-color);
}

.trackIcon {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    width: 150px;
}

.trackIcon:first-child {
    margin-right: 15px;
}

.trackCount {
    font-size: 45px;
    padding-left: 15px;
    color: var(--accent-color);
}